<template>
    <div class="shadow-lg p-3 mb-5 mt-5 container">
        <h1>Schützenbuch <img
            src="/Bilder/book.png"
            width="100"
            height="100"
            class="rounded-circle"></h1>

        <br>
        <h2> Herzlich Willkommen </h2>
        <br>

        <br>
        <div class="form-group row">
            <div
                class="col-sm-10 mb-5 pt-3"
                v-html="getStartSeiteText">
            </div>
        </div>


    </div>
</template>

<script>
export default {
    name: "welcome",

    data: () => ({

    }),
  computed: {
      getStartSeiteText() {
        return this.$store.state.GeneralApp.TextStartseite;
    },
  },

    mounted () {

    },
    created() {

    },

    methods:{

    },


}
</script>

<style scoped>

</style>
